const LOCALE = 'EN-gb';
const FORMAT_DATE_OPTIONS = { year: 'numeric', month: 'short', day: '2-digit' };
const FORMAT_TIME_OPTIONS = { hour: '2-digit', minute: '2-digit' };
const TIME_OFFSET_IN_MS = new Date().getTimezoneOffset() * 60 * 1000;

import { transactionsTpl, paginationTpl, errorTpl } from '../templates';
import { render } from 'lit-html';

import Base from './base';

import { get } from '../utils/http';

class Transactions extends Base {
	constructor(el) {
		super(el);

		this.table = this.query('.js-table');
		this.actionBar = this.query('.js-action-bar');

		this.on('click', e => this.onClick(e));
	}

	start() {
		this.getTransactions(document.location.search);
	}

	onClick(e) {
		const { target } = e;

		const paginateItem = target.closest('.js-paginate');

		if (paginateItem) {
			e.preventDefault();

			this.paginate(paginateItem);
		}
	}

	async getTransactions(query = '') {
		this.loadMask.show();

		try {
			const data = await get(`/api/transactions${query}`);

			this.render(data);

			this.scrollTop();

			this.setActionBarState(data.pagination);
		} catch (err) {
			render(errorTpl('Could not load transactions. Please try again.'), this.el);
		} finally {
			this.loadMask.hide();
		}
	}

	isToday(date) {
		return new Date(date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);
	}

	getFormattedDate(value) {
		if (!value) {
			return null;
		}

		const date = new Date(value).getTime() - TIME_OFFSET_IN_MS;

		if (this.isToday(date)) {
			return new Date(date).toLocaleTimeString(LOCALE, FORMAT_TIME_OPTIONS);
		}

		return new Date(date).toLocaleDateString(LOCALE, FORMAT_DATE_OPTIONS);
	}

	render({ items, pagination, tableHeaders }) {
		this.table.classList.add('table-wrapper');

		render(transactionsTpl(items, tableHeaders), this.table);
		render(paginationTpl(pagination), this.actionBar);
	}

	scrollTop() {
		const el = this.query('.js-dashboard-body', document);

		el.scrollTop = 0;
	}

	setActionBarState(pagination) {
		if (pagination && pagination.hasPages) {
			this.actionBar.hidden = false;
		}
	}

	paginate(target) {
		const { search } = new URL(target.href);

		this.getTransactions(search);

		history.replaceState({}, 'Paginate', search);
	}
}

export default Transactions;
