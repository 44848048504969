import { schemaTpl } from '../../templates';
import { render } from 'lit-html';

import Base from '../base';
import SchemaFormPaste from './schema-form-paste';
import SchemaFormManual from './schema-form-manual';

class Schema extends Base {
	constructor(el, group) {
		super(el);

		this.group = group;

		if (group.occurs) {
			this.render('schema', group);
		} else {
			this.render('form');
		}

		this.on('click', e => this.onClick(e));

		this.setEventbusListeners();
	}

	setEventbusListeners() {
		if (!this.eventbus.all.get('schema:apply')) {
			this.eventbus.on('schema:apply', data => this.applySchema(data));
		}
	}

	render(section, data) {
		switch (section) {
			case 'form':
				this.renderFormPaste(data);
				break;
			case 'manual':
				this.renderFormManual(data);
				break;
			case 'schema': {
				this.renderTable(data);
				break;
			}
			case 'preview': {
				this.renderPreview(data);
				break;
			}
			default:
		}
	}

	renderFormPaste() {
		render(schemaTpl('form', this.group.fields), this.el);

		const el = this.query('.js-form');

		this.form = new SchemaFormPaste(el, this.group, this);
	}

	renderFormManual() {
		render(schemaTpl('manual'), this.el);

		const el = this.query('.js-form');

		this.form = new SchemaFormManual(el, this.group);
	}

	renderTable(schema) {
		render(schemaTpl('table', schema), this.el);
	}

	renderPreview({ schemaTable, errors, schemaFormData }) {
		const { group: { fields } } = this;

		this.schemaFormData = schemaFormData;

		render(schemaTpl('preview', { fields, schemaTable, errors }), this.el);
	}

	onClick(e) {
		const { target } = e;

		if (target.classList.contains('js-paste')) {
			this.render('form');
		}

		if (target.classList.contains('js-manual')) {
			this.render('manual');
		}

		if (target.classList.contains('js-apply')) {
			this.applySchema(this.schemaFormData);
		}

		if (target.classList.contains('js-clear-schema')) {
			this.applySchema(this.group.defaultValues, true);
		}
	}

	applySchema(schemaFormData, isEmpty) {
		this.eventbus.emit('transaction:save', schemaFormData);

		this.eventbus.emit('modal:close');

		this.eventbus.emit('notification:show', {
			text: isEmpty ? `${this.group.title} successfully removed.` : `${this.group.title} successfully applied.`,
		});
	}
}

export default Schema;
