import { html } from 'lit-html';

const errorTpl = error => html`
	<div class="content-wrapper">
		<div class="content-wrapper__body">
			<div class="placeholder text-left">
				<h2>Something went wrong</h2>
				<p class="placeholder__content">${error}</p>
				<a href="/" class="button">Back</a>
			</div>
		</div>
	</div>
`;

export {
	errorTpl,
};
