import { render } from 'lit-html';

import Form from '../form';
import { schemaManualRowsTpl } from '../../templates/schema';

const ID_PREFIX = 'schemaField';

class SchemaFormManual extends Form {
	constructor(el, group) {
		super(el);

		this.group = group;
		this.rows = [];

		this.body = this.query('.js-body');

		this.createRows(this.group.occurs ? 'list' : 'add');

		this.on('change', e => this.onChange(e));
		this.on('click', e => this.onClick(e));
	}

	onChange(e) {
		this.setRowValues(e.target);
	}

	onClick(e) {
		const { target } = e;

		if (target.classList.contains('js-clone')) {
			this.createRows('clone', target.dataset.id);
		}

		if (target.classList.contains('js-add')) {
			this.createRows('add', target.dataset.id);
		}

		if (target.classList.contains('js-remove')) {
			this.createRows('remove', target.dataset.id);
		}

		if (target.classList.contains('js-clear-rows')) {
			this.clearRows();
		}
	}

	createRows(method, id) {
		switch (method) {
			case 'list':
				this.listRows();
				break;
			case 'add':
				this.addRow(id);
				break;
			case 'remove':
				this.removeRow(id);
				break;
			case 'clone':
				this.cloneRow(id);
				break;
			default:
		}

		this.render();

		this.fieldValidator.formatFields();
	}

	listRows() {
		const { elements } = this.group;

		this.rows = elements.map((element, i) => ({
			id: `${ID_PREFIX}-${i}`,
			fields: element,
		}));
	}

	addRow(fieldId) {
		const index = this.rows.findIndex(row => row.id === fieldId);
		const id = `${ID_PREFIX}-${Date.now()}`;

		const fields = this.group.fields.map(field => ({ ...field, id: `${field.name}-${id}` }));

		this.rows.splice(index + 1, 0, { id, fields });
	}

	removeRow(id) {
		const index = this.rows.findIndex(row => row.id === id);

		if (index === -1) {
			return;
		}

		this.rows.splice(index, 1);
	}

	cloneRow(fieldId) {
		const id = `${ID_PREFIX}-${Date.now()}`;
		const row = this.rows.find(r => r.id === fieldId);
		const index = this.rows.indexOf(row);

		const fields = row.fields.map(r => ({ ...r, id: `${r.name}-${id}` }));

		this.rows.splice(index + 1, 0, { id, fields });
	}

	clearRows() {
		this.rows = [];

		this.createRows('add');
	}

	render() {
		render(schemaManualRowsTpl(this.rows), this.body);
	}

	setRowValues(target) {
		const group = target.closest('.js-field-group');
		const { id } = group.dataset;
		const row = this.rows.find(r => r.id === id);

		row.fields.forEach((field) => {
			const input = this.query(`[name='${field.name}']`, group);

			field.value = this.fieldValidator.unformat(input.value, field.validateType);
		});
	}

	getFormData() {
		const formData = new FormData(this.el);
		const body = {};

		formData.forEach((val, key) => {
			const value = this.getFieldValue(val, key);

			if (key in body) {
				body[key].push(value);
			} else {
				body[key] = [value];
			}
		});

		return body;
	}

	async submitForm() {
		const body = this.getFormData();

		this.eventbus.emit('schema:apply', body);
	}
}

export default SchemaFormManual;
