import { transactionTpl, errorTpl } from '../templates';
import { render } from 'lit-html';

import Form from './form';
import Modal from './modal';
import ProductDescriptions from './product-descriptions';
import Schema from './schema';
import FormState from './form-state';

import { get, post, put } from '../utils/http';

class Transaction extends Form {
	constructor(el) {
		super(el);

		this.id = this.query('[name=id]').value;
		this.body = this.query('.js-form-body');
		this.actionBar = this.query('.js-action-bar');
		this.documentsButton = this.query('.js-documents');

		this.on('change', e => this.onChange(e));
		this.on('click', e => this.onClick(e));
		this.on('input', e => this.onInput(e));

		this.eventbus.on('transaction:autofill', () => this.onAutofill());
		this.eventbus.on('transaction:save', data => this.onSave(data));

		this.modal = new Modal(this.el, { fluid: true });
		this.state = new FormState(this.query('.js-state'), el);
	}

	start() {
		this.getTransaction();
	}

	async getTransaction() {
		this.loadMask.show();

		try {
			const data = await get(`/api/transactions/${this.id}`);

			this.render(data);

			this.data = data;

			this.formatFields();

			this.setTitle(data.transactionType);

			this.setActionBarState();
		} catch (err) {
			render(errorTpl('Could not load transaction. Please try again.'), this.el);
		} finally {
			this.loadMask.hide();
		}
	}

	render({ elements }) {
		render(transactionTpl(elements), this.body);
	}

	setTitle(title) {
		const el = this.query('.js-title', document);

		el.innerText = title;
		document.title = [title, document.title].join(' - ');
	}

	setActionBarState() {
		this.actionBar.hidden = false;

		this.documentsButton.disabled = !this.data.productDescriptions.length;
	}

	formatFields() {
		try {
			this.fieldValidator.formatFields();
		} catch (err) {
			this.eventbus.emit('notification:show', {
				text: 'Could not format fields.',
				type: 'error',
			});
		}
	}

	onChange(e) {
		if (e.target.classList.contains('js-control')) {
			this.onSave();
		}
	}

	onInput(e) {
		const { target } = e;

		if (target.classList.contains('js-filter-selection-list')) {
			this.filterSelectionList(target);
		}
	}

	onClick(e) {
		const { target } = e;

		if (target.classList.contains('js-schema')) {
			this.openSchema(target.dataset.groupId);
		}

		if (target.classList.contains('js-quick-select')) {
			this.quickSelect(target);
		}

		if (target === this.documentsButton) {
			this.openDescriptions();
		}
	}

	findGroup(elements, id) {
		if (!elements) {
			return null;
		}

		for (const element of elements) {
			if (element.id === id && element.type === 'group') {
				return element;
			}

			const group = this.findGroup(element.elements, id);

			if (group) {
				return group;
			}
		}

		return null;
	}

	openSchema(groupId) {
		try {
			const elements = this.data.elements.reduce((acc, element) => acc.concat(element), []);
			const group = this.findGroup(elements, groupId);
			const el = document.createElement('div');

			this.schema = new Schema(el, group);

			this.modal.open(el, group.title);
		} catch (err) {
			this.eventbus.emit('notification:show', {
				text: 'Could not open schedule. Please try again.',
				type: 'error',
			});
		}
	}

	quickSelect(target) {
		const field = target.closest('.field');
		const control = this.query('.js-control', field);

		control.value = target.dataset.value;

		this.fieldValidator.validateField(control);

		this.state.setHideErrorsState();

		this.onSave();
	}

	openDescriptions() {
		try {
			const el = document.createElement('div');

			this.schema = new ProductDescriptions(el, this.data.productDescriptions);

			this.modal.open(el, 'Product descriptions');
		} catch (err) {
			this.eventbus.emit('notification:show', {
				text: 'Could not open descriptions. Please try again.',
				type: 'error',
			});
		}
	}

	getFormData(schema) {
		const formData = new FormData(this.el);
		let body = {};

		formData.forEach((val, key) => {
			const value = this.getFieldValue(val, key);

			if (key in body) {
				body[key].push(value);
			} else {
				body[key] = [value];
			}
		});

		if (schema) {
			body = { ...body, ...schema };
		}

		return JSON.stringify(body);
	}

	onAutofill() {
		const body = this.getFormData();

		this.autofillForm(body);
	}

	async autofillForm(body) {
		this.state.set(102);

		try {
			const data = await put('/api/transactions/autofill', body);

			this.render(data);

			this.data = data;

			this.state.set(200);

			this.fieldValidator.validateFields(false, true);

			this.formatFields();

			this.eventbus.emit('notification:show', {
				text: 'Form successfully autofilled with predefined values.',
				type: 'info',
			});
		} catch (err) {
			this.state.set(500);
		}
	}

	onSave(schema) {
		const body = this.getFormData(schema);

		this.saveForm(body);
	}

	async saveForm(body) {
		this.state.set(102);

		try {
			const data = await put('/api/transactions', body);

			this.render(data);

			this.formatFields();

			this.data = data;

			this.state.set(200);
		} catch (err) {
			this.state.set(500);
		}
	}

	async submitForm() {
		const body = this.getFormData();

		try {
			const response = await post('/api/termsheets', body);

			if (response.buffer) {
				this.download(response);

				return;
			}

			this.render(response);

			this.scrollToField('.has-errors');
		} catch (err) {
			this.eventbus.emit('notification:show', {
				text: 'Could not download term sheet. Please try again.',
				type: 'error',
			});
		} finally {
			this.toggleSubmitterState();
		}
	}

	download({ buffer, filename }) {
		const blob = new Blob([Uint8Array.from(buffer.data)]);
		const url = URL.createObjectURL(blob);
		const link = document.createElement('a');

		link.href = url;
		link.setAttribute('download', filename);

		document.body.appendChild(link);

		link.click();
		link.remove();
	}

	filterSelectionList(el) {
		const { value } = el;
		const { target } = el.dataset;
		const fields = this.queryAll(`[data-list="${target}"`);

		fields.forEach((field) => {
			field.hidden = field.dataset.value.indexOf(value.toLowerCase()) === -1;
		});
	}
}

export default Transaction;
