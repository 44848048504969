/* eslint-disable no-undefined */
import { html } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined';
import { classMap } from 'lit-html/directives/class-map.js';

import formatter from '../utils/formatter';

const labelTpl = field => html`
	<label class="field__label" for="${field.id}">
		${field.title}${field.isRequired ? '*' : ''}
	</label>
`;

const inputTpl = field => html`
	<input
		class="field__control js-control"
		name="${field.name}"
		id="${field.id}"
		type="${field.inputType}"
		.value="${field.value}"
		?required="${field.isRequired}"
		?readonly="${field.isReadonly}"
		max=${ifDefined(field.max ? field.max : undefined)}
	>
`;

const optionTpl = option => html`
	<option .value="${option.id}" ?selected="${option.isSelected}">
		${option.name}
	</option>
`;

const selectTpl = field => html`
	<select
		class="field__control js-control"
		name="${field.name}"
		id="${field.id}"
		?required="${field.isRequired}"
	>
		<option value="">–</option>
		${field.options.map(o => optionTpl(o))}
	</select>
`;

const toggleSwitchTpl = field => html`
	<div class="toggle-switch">
		<input
			class="toggle-switch__input js-control"
			name="${field.name}"
			id="${field.id}"
			type="${field.inputType}"
			value="${field.value}"
			.checked="${field.isChecked}"
			?required="${field.isRequired}"
			?readonly="${field.isReadonly}"
		>
		<label class="toggle-switch__label" for="${field.id}">
			<span class="sr-only">${field.title}</span>
		</label>
	</div>
`;

const selectListFieldTpl = (option, field) => html`
	<div class="field" data-value="${option.id}" data-list="${field.id}">
		<div class="custom-input">
			<input
				class="js-control"
				name="${field.name}"
				id="${field.id}-${option.id}"
				type="${field.inputType}"
				value="${option.id}"
				?required="${option.isRequired}"
				.checked="${option.isChecked}"
			>
			<label for="${field.id}-${option.id}">${option.name}</label>
		</div>
	</div>
`;

const selectListLabelTpl = (field, option) => html`
	<label for="${field.id}-${option.id}" class="label is-active" data-tippy-content="Deselect">
		${option.name}
	</label>
`;

const selectionListTpl = field => html`
	<small class="text-muted float-right">selected: ${field.values.length}</small>
	<div class="field__list js-selection-list" data-min-occurs="${field.minOccurs}">
		<input class="field__control js-filter-selection-list" id="${field.id}" data-target="${field.id}" type="search" placeholder="Filter...">
		${field.options.map(o => selectListFieldTpl(o, field))}
	</div>
	<div class="label-container">
		${field.options.map(o => (o.isChecked ? selectListLabelTpl(field, o) : ''))}
	</div>
`;

const errorTpl = (errors = []) => html`
	<div class="field__error js-error" hidden></div>
	${errors.map(error => html`
		<div class="field__error">${error}</div>
	`)}
	${errors.length ? html`<span class="field__error-note js-error-note" hidden>You cannot hide this error</span>` : ''}
`;

const controlTpl = (field) => {
	if (field.isCheckboxList) {
		return selectionListTpl(field);
	}

	if (field.inputType === 'checkbox') {
		return toggleSwitchTpl(field);
	}

	if (field.options) {
		return selectTpl(field);
	}

	return inputTpl(field);
};

const quickSelectOptionTpl = option => html`
	<button
		type="button"
		class="label js-quick-select"
		data-value="${option.value}"
		data-tippy-content="${formatter.format(option.timestamp, 'date')}"
	>
		${option.label}
	</button>
`;

const quickSelectTpl = (options = []) => (options.length
	? html`
		<div class="label-container">
			${options.map(option => quickSelectOptionTpl(option))}
		</div>`
	: ''
);

const fieldTpl = field => html`
	<div class="field js-field ${classMap({
		'has-errors': field.errors && field.errors.length,
		'field--list': field.isCheckboxList,
	})}"
		data-field="${field.name}"
		data-validation="${ifDefined(field.validateType ? field.validateType : undefined)}"
		?data-use-unformatted-value="${field.useUnformattedValue}"
	>
		${labelTpl(field)}
		${controlTpl(field)}
		${errorTpl(field.errors)}

		${quickSelectTpl(field.quickSelectOptions)}
	</div>
`;

export {
	errorTpl,
	fieldTpl,
};
