import { html } from 'lit-html';

import { iconTpl } from './icon';

const paginationItemTpl = item => html`
	<a class="pagination__item js-paginate ${item.isCurrentPage ? 'is-active' : ''}" href="${item.href}">
		${item.icon ? iconTpl(item.icon, '', 's') : item.label}
	</a>
`;

const paginationItemDisabledTpl = item => html`
	<div class="pagination__item is-disabled">
		${item.icon ? iconTpl(item.icon, '', 's') : item.label}
	</div>
`;

const paginationTpl = pagination => (pagination.hasPages
	? html`
		<div class="pagination">
			<div class="pagination__total">
				${pagination.total.start}–${pagination.total.end} of ${pagination.total.total}
			</div>
			<div class="pagination__items">
				${pagination.items.map(item => html`
					${item.isDisabled ? paginationItemDisabledTpl(item) : paginationItemTpl(item)}
				`)}
			</div>
		</div>
	`
	: '');

export {
	paginationTpl,
};
