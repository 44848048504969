/* eslint-disable no-nested-ternary */
import { html } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';
import { classMap } from 'lit-html/directives/class-map.js';

import formatter from '../utils/formatter';

import { fieldTpl } from './form';
import { iconTpl } from './icon';

const schemaTableHeadersTpl = (fields, showIndex = false) => html`
	<tr>
		${showIndex ? html`<th><span class="sr-only">Row number</span></th>` : ''}
		${fields.map(field => html`
			<th class=${field.type.match(/^money|number$/) ? 'text-right' : ''}>
				${field.title}
			</th>
		`)}
	</tr>
`;

const schemaTableValueTpl = (field, key) => {
	if (!field.hasError && key === 'value') {
		return field.value ? formatter.format(field.value, field.type) : '–';
	}

	return field[key];
};

const schemaTableRowTpl = (row, index, key = 'value') => html`
	<tr>
		${typeof index === 'number' ? html`<td>${index + 1}</td>` : ''}
		${row.map(r => html`
			<td class="${classMap({ 'has-error': r.hasError, 'text-right': r.type.match(/^money|number$/) })}">
				${schemaTableValueTpl(r, key)}
			</td>
		`)}
	</tr>
`;

const schemaTableTpl = (elements, fields) => html`
	<div class="table-wrapper">
		<table>
			<thead>
				${schemaTableHeadersTpl(fields, true)}
			</thead>
			<tbody>
				${elements.map((element, index) => html`
					${schemaTableRowTpl(element, index)}
				`)}
			</tbody>
		</table>
	</div>
`;

const schemaFieldsTableTpl = fields => html`
	<div class="table-wrapper">
		<table>
			<thead>
				${schemaTableHeadersTpl(fields)}
			</thead>
			<tbody>
				${schemaTableRowTpl(fields, null, 'type')}
			</tbody>
		</table>
	</div>
`;

const schemaSettingsTpl = fields => html`
	${fields.map(field => fieldTpl(field))}
`;

const schemaFormTpl = fields => html`
	${schemaFieldsTableTpl(fields)}
	<form class="js-form" novalidate>
		<div class="field-group js-settings"></div>
		<div class="field js-field">
			<label class="field__label" for="schema">Paste from Excel</label>
			<textarea name="schema" id="schema" class="field__control js-control" required></textarea>
			<div class="field__error js-error" hidden></div>
		</div>
		<div class="action-bar action-bar--flush">
			<button class="button js-manual" type="button">
				${iconTpl('pencil')}
				Manual input
			</button>
			<button class="button button--secondary js-submit">
				${iconTpl('table')}
				Convert to table
			</button>
		</div>
	</form>
`;

const schemaManualRowsTpl = rows => repeat(rows, row => row.id, row => html`
	<div class="field-group js-field-group" data-id="${row.id}">
		${row.fields.map(field => fieldTpl(field))}
		<div class="field-group__buttons">
			<button class="button js-clone" type="button" data-id="${row.id}" aria-label="Copy row">
				${iconTpl('copy')}
			</button>
			<button class="button js-add" type="button" data-id="${row.id}" aria-label="Add row">
				${iconTpl('plus')}
			</button>
			<button class="button js-remove" type="button" data-id="${row.id}" ?disabled=${rows.length === 1}  aria-label="Remove row">
				${iconTpl('minus')}
			</button>
		</div>
	</div>
`);

const schemaManualTpl = html`
	<form class="js-form" novalidate>
		<div class="js-body"></div>
		<div class="action-bar action-bar--flush">
			<button class="button js-clear-rows" type="button">
				${iconTpl('clear')}
				Clear all rows
			</button>
			<button class="button button--primary js-submit">
				${iconTpl('submit')}
				Submit schedule
			</button>
		</div>
	</form>
`;

const schemaOverviewTpl = ({ elements, fields }) => html`
	${schemaTableTpl(elements, fields)}
	<div class="action-bar action-bar--flush">
		<div>
			<button class="button js-manual">
				${iconTpl('pencil')}
				Edit schedule
			</button>
			<button class="button js-paste">
				${iconTpl('clipboard')}
				Paste schedule
			</button>
		</div>
		<button class="button button--primary js-clear-schema">
			${iconTpl('clear')}
			Clear schedule
		</button>
	</div>
`;

const schemaPreviewTpl = ({ fields, schemaTable, errors }) => html`
	<p>Errors: ${errors} / Total periods: ${schemaTable.length}</p>
	${schemaTable.length ? schemaTableTpl(schemaTable, fields) : ''}
	<div class="action-bar action-bar--flush">
		<button class="button js-paste">
			${iconTpl('clipboard')}
			Paste again
		</button>
		<button class="button button--primary js-apply" ?disabled=${errors}>
			${iconTpl('submit')}
			Submit schedule
		</button>
	</div>
`;

const schemaTpl = (section, data) => {
	if (section === 'form') {
		return schemaFormTpl(data);
	}

	if (section === 'manual') {
		return schemaManualTpl;
	}

	if (section === 'preview') {
		return schemaPreviewTpl(data);
	}

	return schemaOverviewTpl(data);
};

export {
	schemaTpl,
	schemaTableTpl,
	schemaManualTpl,
	schemaManualRowsTpl,
	schemaSettingsTpl,
};
