import Base from './base';

class FormState extends Base {
	constructor(el, form) {
		super(el);

		this.form = form;
		this.body = this.query('.js-body');
		this.autoFillButton = this.query('.js-autofill');
		this.clearErrorsButton = this.query('.js-hide-errors');
		this.saveButton = this.query('.js-save');

		this.on('click', e => this.onClick(e));
		this.on('online', e => this.onUpdateOnlineStatus(e), window);
		this.on('offline', e => this.onUpdateOnlineStatus(e), window);

		this.on('focusout', () => this.setHideErrorsState(), this.form);
		this.on('submit', () => this.setHideErrorsState(), this.form);

		this.loadMask.setEl('.js-mask');
	}

	onClick(e) {
		const { target } = e;

		if (target.classList.contains('js-autofill')) {
			this.autofillForm();
		}

		if (target.classList.contains('js-save')) {
			this.saveForm();
		}

		if (target.classList.contains('js-hide-errors')) {
			this.hideErrors();
		}
	}

	onUpdateOnlineStatus({ type }) {
		this.notify(type);

		if (type === 'online') {
			this.saveForm();
		}
	}

	setHideErrorsState() {
		const hasErrors = this.query('.is-invalid', this.form);

		this.clearErrorsButton.disabled = !hasErrors;
	}

	notify(status) {
		const text = status === 'online'
			? 'Internet connection restored.'
			: 'No internet connection.';
		const type = status === 'online'
			? 'info'
			: 'warning';

		this.eventbus.emit('notification:show', { text, type });
	}

	hideErrors() {
		this.eventbus.emit('fieldValidator:hideErrors');

		this.setHideErrorsState();
	}

	autofillForm() {
		// eslint-disable-next-line no-alert
		if (!confirm('Are you sure you want to autofill the form? Some already entered values could be overwritten.')) {
			return;
		}

		this.eventbus.emit('transaction:autofill');
	}

	saveForm() {
		this.eventbus.emit('transaction:save');
	}

	// Status codes:
	// 102 Processing (loading)
	// 200 OK
	// 500 Error
	set(code) {
		let html;

		switch (code) {
			case 102:
				html = `
					<small class="text-muted">Saving document</small>
				`;
				break;
			case 200:
				html = `
					<small class="text-muted" data-tippy-content="Last saved: ${new Date().toLocaleString('en-GB')}">All changes have been saved successfully</small>
				`;
				break;
			case 500:
				html = `
					<small class="text-error">Could not save transaction</small>
				`;
				break;
			default:
		}

		if (code === 102) {
			this.loadMask.show();
		} else {
			this.loadMask.hide();
		}

		this.saveButton.disabled = code !== 500;
		this.body.innerHTML = html;
	}
}

export default FormState;
