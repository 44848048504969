/* eslint-disable no-undefined */
import { html } from 'lit-html';
import { ifDefined } from 'lit-html/directives/if-defined';
import { repeat } from 'lit-html/directives/repeat';

import { iconTpl } from './icon';

import formatter from '../utils/formatter';

const transactionsEmptyTpl = html`
	<div class="placeholder">
		${iconTpl('document', 'placeholder__icon')}
		<h2>No transactions</h2>
		<p class="placeholder__content">No transactions found.</p>
		<a class="button" href="/transactions/create">Create new transaction
		</a>
	</div>
`;

const transactionsTableHeadersTpl = headers => html`
	<thead>
		<tr>
			${headers.map(header => html`
				<th class="${header.classNames}">
					<a class="js-paginate" href="${header.href}">${header.label}</a>
					${iconTpl(header.icon)}
				</th>
			`)}
		</tr>
	</thead>
`;

const transactionsTableRowTpl = row => html`
	<tr>
		<td>
			${iconTpl('document', 'icon-table', 'l')}
			<a href="/transactions/${row.id}">${row.transactionType}</a>
		</td>
		<td>${row.client ? row.client : '‒'}</td>
		<td>
			<span data-tippy-content="Created at ${formatter.format(row.createdAt, 'dateTime')}">
				${formatter.format(row.createdAt, 'dateOrTimeToday')}
			</span>
		</td>
		<td>
			<span data-tippy-content="${ifDefined(row.lastModifiedAt ? `Last modified at ${formatter.format(row.lastModifiedAt, 'dateTime')}` : undefined)}">
				${row.lastModifiedAt ? formatter.format(row.lastModifiedAt, 'dateOrTimeToday') : '‒'}
			</span>
		</td>
	</tr>
`;

const transactionsTableTpl = (items, tableHeaders) => html`
	<table>
		${transactionsTableHeadersTpl(tableHeaders)}
		<tbody>
			${repeat(items, row => row.id, row => html`
				${transactionsTableRowTpl(row)}
			`)}
		</tbody>
	</table>
`;

const transactionsTpl = (items, tableHeaders) => (items.length
	? transactionsTableTpl(items, tableHeaders)
	: transactionsEmptyTpl
);

export {
	transactionsTpl,
};
