/* eslint-disable no-undefined */
import { html } from 'lit-html';
import { repeat } from 'lit-html/directives/repeat';

import { errorTpl, fieldTpl } from './form';
import { iconTpl } from './icon';
import { schemaTableTpl } from './schema';

const groupTpl = group => html`
	<div class="fieldset__group">
		<legend class="fieldset__legend">
			${group.title} (${group.fields.length})
			<small class="float-right font-normal">Total periods: ${group.occurs}</small>
		</legend>
		${group.elements.map(element => element.map(field => html`
			<input
			name="${field.name}"
			value="${field.value}"
			type="hidden"
		>`))}

		${group.occurs ? html`${schemaTableTpl([group.elements[0]], group.fields)}` : ''}

		<div class="field js-field" data-validation="${group.type}" data-occurs="${group.occurs}">
			${group.isValid ? '' : html`<input class="sr-only js-control" ?required="${group.isRequired}" tabindex="-1">`}
			<button class="button field__button js-schema" type="button" data-group-id="${group.id}">
				${group.occurs
		? html`
				${iconTpl('pencil')}
				Open ${group.title}
			`
		: html`
				${iconTpl('plus')}
				Add ${group.title}
			`}
			</button>
			${group.isValid ? '' : errorTpl()}
			${group.occurs && !group.isValid ? html`<small class="text-error">${group.title} is not valid. Please complete all fields.</small>` : ''}
		</div>
	</div>
`;

const elementTypeTpl = (element) => {
	if (element.type === 'group') {
		return groupTpl(element);
	}

	return fieldTpl(element);
};

const fieldsetTpl = fieldset => html`
	<fieldset class="fieldset">
		${fieldset.title ? html`<legend class="fieldset__legend">${fieldset.title}</legend>` : ''}
		${repeat(fieldset.elements, element => element.id, element => html`
			${element.type === 'section' ? fieldsetTpl(element) : elementTypeTpl(element)}
		`)}
	</fieldset>
`;

const transactionTpl = elements => elements.map(element => html`
	<div class="form__column">
		${element.map(section => fieldsetTpl(section))}
	</div>
`);

export {
	transactionTpl,
};
