import { productDescriptionsTpl } from '../templates';
import { render } from 'lit-html';

import Base from './base';

class ProductDescriptions extends Base {
	constructor(el, descriptions) {
		super(el);

		this.descriptions = descriptions;

		this.render();
	}

	render() {
		render(productDescriptionsTpl(this.descriptions), this.el);
	}
}

export default ProductDescriptions;
