import { html } from 'lit-html';
import { unsafeSVG } from 'lit-html/directives/unsafe-svg';

const iconTpl = (icon, classes = '', size = 'm') => html`
	${unsafeSVG(`
		<svg viewBox="0 0 24 24" class="icon icon--${size} ${classes}">
			<use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/icons/sprite.svg#icon-${icon}"></use>
		</svg>
	`)}
`;

export {
	iconTpl,
};
