import { html } from 'lit-html';

import { iconTpl } from './icon';

const productDescriptionsTpl = (descriptions) => {
	if (descriptions.length) {
		return html`
			<ol class="list-group">
				${descriptions.map(description => html`
					<li class="list-group__item">
						${iconTpl('download', 'list-group__icon')}
						<a href="/documents/download/${description.fileId}">
							${description.fileName}
						</a>
					</li>
				`)}
			</ol>
		`;
	}

	return html`<p>No product descriptions available.</p>`;
};

export {
	productDescriptionsTpl,
};
