import numeral from 'numeral';

const LOCALE = 'EN-gb';
const FORMAT_DATE_OPTIONS = { year: 'numeric', month: 'short', day: '2-digit' };
const FORMAT_TIME_OPTIONS = { hour: '2-digit', minute: '2-digit' };
const FORMAT_DATE_TIME_OPTIONS = { ...FORMAT_DATE_OPTIONS, ...FORMAT_TIME_OPTIONS };
const TIME_OFFSET_IN_MS = new Date().getTimezoneOffset() * 60 * 1000;

numeral.register('locale', LOCALE, {
	delimiters: {
		thousands: ',',
		decimal: '.',
	},
	abbreviations: {
		thousand: 'k',
		million: 'm',
		billion: 'b',
		trillion: 't',
	},
	currency: {
		symbol: 'EUR',
	},
});

numeral.nullFormat('');
numeral.locale(LOCALE);

const dateWithTimeOffset = value => new Date(value).getTime() - TIME_OFFSET_IN_MS;

const isToday = value => new Date(value).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);

const formatDateTime = value => new Date(value).toLocaleString(LOCALE, FORMAT_DATE_TIME_OPTIONS);

const formatDate = value => new Date(value).toLocaleDateString(LOCALE, FORMAT_DATE_OPTIONS);

const formatTime = value => new Date(value).toLocaleTimeString(LOCALE, FORMAT_TIME_OPTIONS);

const formatNumber = value => numeral(value).format('0,0[.]0000');

const formatCurrency = value => numeral(value).format('0,0[.]00');

const unformatValue = value => numeral(value).value();

const format = (value, type) => {
	switch (type) {
		case 'dateOrTimeToday': {
			const date = dateWithTimeOffset(value);

			return isToday(date) ? formatTime(date) : formatDate(date);
		}
		case 'dateTime': {
			const date = dateWithTimeOffset(value);

			return formatDateTime(date);
		}
		case 'date': {
			const date = dateWithTimeOffset(value);

			return formatDate(date);
		}
		case 'money': {
			return formatCurrency(value);
		}
		case 'number': {
			return formatNumber(value);
		}
		default: {
			return value;
		}
	}
};

const unformat = (value, type) => {
	switch (type) {
		case 'money':
		case 'number':
			return unformatValue(value);
		default:
			return value;
	}
};

export default {
	format,
	unformat,
};
