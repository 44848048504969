import formatter from '../utils/formatter';

import Base from './base';

class FieldValidator extends Base {
	constructor(el) {
		super(el);

		this.on('focusout', e => this.onFocusOut(e));
		this.on('input', e => this.onInput(e));

		this.eventbus.on('fieldValidator:hideErrors', () => this.hideErrors());

		this.fields = this.el.getElementsByClassName('js-field');
	}

	onInput(e) {
		const { target } = e;

		if (!this.isFormControl(target)) {
			return;
		}

		this.validateField(target);
	}

	onFocusOut(e) {
		const { target } = e;

		if (!this.isFormControl(target)) {
			return;
		}

		this.trimValue(target);

		this.setFormattedValue(target);

		this.validateField(target, true);
	}

	isFormControl(target) {
		return target.classList.contains('js-control');
	}

	getField(input) {
		return input.closest('.js-field');
	}

	getValidationType(field) {
		return field.dataset.validation;
	}

	trimValue(target) {
		if (target.type === 'date') {
			return;
		}

		target.value = target.value.trim();
	}

	validateField(target, showErrorMessage, isSilent = false) {
		const selectionList = target.closest('.js-selection-list');

		if (selectionList) {
			this.validateSelectionList(target, selectionList, !isSilent, isSilent);

			return;
		}

		this.validateInput(target, showErrorMessage, isSilent);
	}

	validateFields(showErrorMessage = true, isSilent = false) {
		Array.from(this.fields).forEach((field) => {
			const control = this.query('.js-control', field);

			if (!control) {
				return;
			}

			this.validateField(control, showErrorMessage, isSilent);
		});
	}

	validateSelectionList(target, list, showErrorMessage, isSilent) {
		const { minOccurs } = list.dataset;
		const { length } = this.queryAll(`[name=${target.name}]:checked`);
		const isInvalid = length < minOccurs;

		this.toggleError(list, isInvalid, showErrorMessage, isSilent);
	}

	validateInput(target, showErrorMessage, isSilent) {
		const isInvalid = !target.checkValidity();

		this.toggleError(target, isInvalid, showErrorMessage, isSilent);
	}

	toggleError(target, isInvalid, showErrorMessage, isSilent) {
		this.toggleInvalidState(target, isInvalid, isSilent);

		this.toggleErrorMessage(target, isInvalid, showErrorMessage);
	}

	formatFields() {
		Array.from(this.fields).forEach((field) => {
			const input = this.query('.js-control', field);

			if (input && input.value) {
				this.setFormattedValue(input);
			}
		});
	}

	setFormattedValue(input) {
		const field = this.getField(input);
		const validationType = this.getValidationType(field);

		if (validationType) {
			input.value = this.format(input, validationType);
		}
	}

	toggleInvalidState(el, isInvalid, isSilent) {
		if (isSilent) {
			if (!isInvalid) {
				el.classList.remove('is-invalid');
			}

			return;
		}

		el.classList.toggle('is-invalid', isInvalid);
	}

	toggleErrorMessage(el, isInvalid, showErrorMessage = false) {
		const field = this.getField(el);
		const error = this.query('.js-error', field);

		if (!isInvalid) {
			error.hidden = true;
			error.innerText = '';

			return;
		}

		if (isInvalid && showErrorMessage) {
			error.hidden = false;
			error.innerText = this.errorMessage(el);
		}
	}


	errorMessage(el) {
		const field = this.getField(el);
		const type = this.getValidationType(field) || el.type;

		switch (type) {
			case 'money':
				return 'Please enter a valid amount';
			case 'number':
				return 'Please enter a valid number';
			case 'date':
				return 'Please enter a valid date';
			case 'select-one':
				return 'Please select a value';
			case 'group':
				return this.errorMessageGroup(field);
			case 'occurs':
				return this.errorMessageOccurs(field);
			default:
				return 'Please enter a valid value';
		}
	}

	errorMessageGroup(field) {
		const { occurs } = field.dataset;

		if (parseInt(occurs, 10)) {
			return 'Please complete schedule';
		}

		return 'Please add a schedule';
	}

	errorMessageOccurs(field) {
		const list = this.query('.js-selection-list', field);

		if (list) {
			const { minOccurs } = list.dataset;
			const v = minOccurs > 1 ? 'values' : 'value';

			return `Please select at least ${minOccurs} ${v}`;
		}

		return 'Please select a value';
	}

	hideErrors() {
		Array.from(this.fields).forEach((field) => {
			const control = this.query('.js-control', field);

			if (!control) {
				return;
			}

			const target = control.closest('.js-selection-list') || control;

			this.toggleError(target, false, false, true);
		});

		this.showErrorNotes();
	}

	showErrorNotes() {
		const errorNotes = this.queryAll('.js-error-note');

		errorNotes.forEach(note => (note.hidden = false));
	}

	format(input, type) {
		return formatter.format(input.value, type);
	}

	unformat(value, type) {
		return formatter.unformat(value, type);
	}
}

export default FieldValidator;
