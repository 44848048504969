import { default as conditioner } from 'conditioner-core/conditioner-core';
import * as components from './modules/**/*.mjs';

import { delegate as tippy } from 'tippy.js';

tippy(document.body, {
	target: '[data-tippy-content]',
	placement: 'bottom',
	duration: [0, 0],
	onShow(instance) {
		instance.setContent(instance.reference.dataset.tippyContent);
	},
});

const modules = Object.keys(components).reduce((m, k) => {
	const key = k
		.replace(/\$/g, '/')	// restore directories (from '$' to '/')
		.replace(/_/g, '-');	// restore filename dashes (from '_' to '-')

	m[key] = components[k];

	return m;
}, {});

conditioner.addPlugin({
	moduleGetConstructor: Module => (element) => {
		if (!Module) {
			console.error(`Module for "${element.dataset.module}" does not exist`);

			return false;
		}

		return new Module(element).start();
	},
	moduleImport: name => Promise.resolve(modules[name]),
});

export default function start() {
	return {
		loader: conditioner.hydrate(document.body),
	};
}
