import { render } from 'lit-html';

import Form from '../form';
import { schemaSettingsTpl } from '../../templates/schema';

import { post } from '../../utils/http';

const FIELDS = [
	{
		id: 'dateFormatting',
		name: 'dateFormatting',
		inputType: 'select',
		title: 'Date formatting',
		isRequired: true,
		options: [{
			id: 'dd-mm-yyyy',
			name: 'Day Month Year',
		}, {
			id: 'mm-dd-yyyy',
			name: 'Month Day Year',
		}, {
			id: 'yyyy-mm-dd',
			name: 'Year Month Day',
		}],
	},
	{
		id: 'decimalSeparator',
		name: 'decimalSeparator',
		inputType: 'select',
		title: 'Decimal separator',
		isRequired: true,
		options: [{
			id: 'dot',
			name: 'Dot (10.99)',
		}, {
			id: 'comma',
			name: 'Comma (10,99)',
		}],
	},
	{
		id: 'thousandSeparator',
		name: 'thousandSeparator',
		inputType: 'select',
		title: 'Thousand separator',
		isRequired: true,
		options: [{
			id: 'dot',
			name: 'Dot (10.000.000)',
		}, {
			id: 'comma',
			name: 'Comma (10,000,000)',
		}, {
			id: 'space',
			name: 'Space (10 000 000)',
		}, {
			id: 'none',
			name: 'None (10000000)',
		}],
	},
];

class SchemaFormPaste extends Form {
	constructor(el, group, schemaView) {
		super(el);

		this.group = group;
		this.schemaView = schemaView;
		this.settingsEl = this.query('.js-settings');
		this.settings = this.getSettings();
		this.fields = this.getFields();

		this.renderSettings();

		this.on('change', e => this.onChange(e));
	}

	renderSettings() {
		render(schemaSettingsTpl(this.fields), this.settingsEl);
	}

	onChange(e) {
		const settings = e.target.closest('.js-settings');

		if (settings) {
			this.setSettings(settings);
		}
	}

	getFormData() {
		const formData = new FormData(this.el);
		const { defaultValues, fields } = this.group;
		const data = {
			defaultValues,
			fields,
		};

		for (const [key, value] of formData.entries()) {
			data[key] = value;
		}

		return JSON.stringify(data);
	}

	async submitForm() {
		const body = this.getFormData();

		try {
			const result = await post('/api/schema', body);
			const { headers, schemaTable, schemaFormData, errors } = result;

			this.schemaView.render('preview', { headers, errors, schemaTable, schemaFormData });

			if (errors) {
				this.eventbus.emit('notification:show', {
					text: `Your schedule contains ${errors} errors. Please try again`,
					type: 'warning',
				});
			}
		} catch (err) {
			this.eventbus.emit('notification:show', {
				text: 'Could not process schedule. Please try again.',
				type: 'error',
			});
		} finally {
			this.toggleSubmitterState();
		}
	}

	getFields() {
		const fields = FIELDS.map((field) => {
			const options = field.options.map((option) => {
				const isSelected = this.settings[field.name] === option.id;

				return { ...option, isSelected };
			});

			return { ...field, options };
		});

		return fields;
	}

	getSettings() {
		const settings = localStorage.getItem('settings');

		return JSON.parse(settings) || {};
	}

	setSettings(el) {
		const fields = this.queryAll('.js-control', el);
		const settings = {};

		fields.forEach(field => (settings[field.name] = field.value));

		localStorage.setItem('settings', JSON.stringify(settings));
	}
}

export default SchemaFormPaste;
